import React, { useState, useEffect } from "react";
import StepperGeneric from "../../../sharedComponents/Stepper/StepperGeneric";
import { makeStyles } from "@material-ui/core/styles";
import GeneralData from "../generalData/generalData";
import Gratitude from "../gratitude/gratitude";
import LegalDocuments from "../legalDocuments/legalDocuments";
import PlatformDocuments from "../platformDocuments/platformDocuments";
import Offer from "../offerSelect/mainOffer";
import BackButton from "../../../sharedFunction/backButton";
import { useSelector, useDispatch } from "react-redux";
import { getUserInfo } from "../../../Services/generalDataAmazon";
import SignRequest from "../SignRequest/SignRequest";
import { configActionsButtons } from "../../../../store/actions/app.actions";
import Onboarding from "../../../sharedComponents/Onboarding/Onboarding";
import { userData } from "../../../../store/actions/user.actions";
import ShowBackdrop from "../../../sharedComponents/backDrop";

export const RequestContext = React.createContext();


const useStyles = makeStyles(() => ({
	root: {
		height: "flex",
	},
	steppedComplete: {
		display: "flex"
	}
}));

export default function DataCollection() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const _userData = useSelector((state) => state.user.userInfo);
	const [requestData, setRequestData] = useState(null);
	const [activeStep, setActiveStep] = useState(0);
	const [title, setTitle] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [showOnboarding, setShowOnboarding] = useState(false);
	const [onboardingData, setOnboardingData] = useState([]);
	const [nextData, setNextData] = useState(true);

	const handleClose = () => {
		setShowOnboarding(false);
	};

	const titlehandler = (value) => {
		setTitle(value);
	};

	const activeStephandler = async (data) => {
		if(data != null){
			setNextData(data.waitResponse);
			setIsLoading(true);
			setTimeout(function(){ setIsLoading(false); }, 1000);
		}else{
			await updateUserData();
		}
		var newactiv = activeStep + 1;
		setActiveStep(newactiv);
	};

	const activeBackStephandler = async () => {
		var newactiv = activeStep - 1;
		await updateUserData();
		setActiveStep(newactiv);
	}

	const getSteps = (stepRef) => {
		const stepsArray = [];
		const componentObject = requestData.platform.steps_configuration.steps;
		const componentLabel = requestData.platform.steps_configuration.titles;
		const componentShow = requestData.platform.steps_configuration.show_steps_in_stepper;

		let componentsList = {
			"generalData": {
				title: componentLabel["generalData"], showStep: componentShow["generalData"], submit: () => { }, content:
					<RequestContext.Provider value={{ requestData }}>
						<GeneralData isLoading={isLoading} stepRef={stepRef} activeStephandler={activeStephandler} titlehandler={titlehandler}/>
					</RequestContext.Provider >
			},
			"legalDocuments": {
				title: componentLabel["legalDocuments"], showStep: componentShow["legalDocuments"], submit: () => { }, content:
					<RequestContext.Provider value={{ requestData }}>
						<LegalDocuments waitResponse={false} instructions={requestData.platform.steps_configuration.instructions ? requestData.platform.steps_configuration.instructions["legalDocuments"] : ''} isLoading={isLoading} requiredDocs={requestData.requiredDocs[1]} stepRef={stepRef} titleHandler={titlehandler} activeStephandler={activeStephandler} jsonDocuments={requestData.platform.steps_configuration.documents_configuration} request_id={requestData.request.id}/>
					</RequestContext.Provider >
			},
			"platformDocuments": {
				title: componentLabel["platformDocuments"], showStep: componentShow["platformDocuments"], submit: () => { }, content:
					<RequestContext.Provider value={{ requestData }}>
						<PlatformDocuments requiredDocs={requestData.requiredDocs[2]} stepRef={stepRef} activeStephandler={activeStephandler} />
					</RequestContext.Provider >
			},
			"offersSelection": {
				title: componentLabel["offersSelection"], showStep: componentShow["offersSelection"], submit: () => { }, content:
					<RequestContext.Provider value={{ requestData }}>
						<Offer ref={stepRef} titlehandler={titlehandler} disabled={true} activeStephandler={activeStephandler} requestData={requestData} />
					</RequestContext.Provider >
			},
			"sendRequest": {
				title: componentLabel["sendRequest"], showStep: componentShow["sendRequest"], submit: () => { }, content:
					<RequestContext.Provider value={{ requestData }}>
						<Gratitude ref={stepRef}  titlehandler={titlehandler}/>
					</RequestContext.Provider >
			},
			"signRequest": {
				title: componentLabel["signRequest"], showStep: componentShow["signRequest"], submit: () => { }, content:
					<RequestContext.Provider value={{ requestData }}>
						<SignRequest ref={stepRef} titlehandler={titlehandler} fadQR={requestData.request.contract_url} requestData={requestData} instructions={requestData.platform.steps_configuration.instructions.signRequest}/>
					</RequestContext.Provider >
			}
		};

		let currentStatus = requestData.status.sub_status;
		let statusWorkFlow = requestData.status_work_flow;
		let indexActiveStep = 0;
		let index = 0;

		for (let element in componentObject) {
			let node = componentObject[element];
			var not_undefined = statusWorkFlow.document_step !== undefined && node.document_step !== undefined;

			if (node.sub_status === currentStatus || (not_undefined && statusWorkFlow.document_step === node.document_step)) {
				indexActiveStep = !nextData ? index+1 : index;
			}

			let children = componentsList[node.component];
			stepsArray.push(children);
			index++;
		}
		if((_userData.status.sub_status === 'Revisión de documentos') && (_userData.request.signature_ready)){
		    indexActiveStep++;
		}
		setActiveStep(indexActiveStep);
		return stepsArray;
	};

	const updateUserData = async () => {
		setIsLoading(true);
		let requiredDocs = _userData.requiredDocs;
		const userInfo = await getUserInfo();
		userInfo.requiredDocs = requiredDocs;
		dispatch(userData(userInfo));
		setIsLoading(false);
	}

	useEffect(() => {
		setRequestData(_userData);
	}, [_userData]);

	useEffect(() => {
		if (requestData) {
			if (requestData.request?.show_onboarding) {
				setShowOnboarding(requestData.request?.show_onboarding);
				setOnboardingData(requestData.platform.onboarding);
			}
			dispatch(configActionsButtons({
				backButton: { show: true, disable: false },
				nextButton: { show: true, disable: requestData.status.sub_status === "Registro" ? true : false }
			}));
		}
	}, [requestData, dispatch, activeStep]);

	return (
		<React.Fragment>
			{requestData && (
				<div className={classes.root}>

					<StepperGeneric
						getSteps={getSteps}
						aciveStep={activeStep}
						stepesCompleted={<Gratitude />}
						cancelButton={false}
						backButton={BackButton}
						activeBackStephandler={activeBackStephandler}
						activeStephandler={activeStephandler}
						setIsLoading={setIsLoading}
						pageTitle={title}
					/>
					{onboardingData?.length > 0 && (
						<Onboarding open={showOnboarding} data={onboardingData} handleClose={handleClose} />
					)}
					<ShowBackdrop open={isLoading} data-test="backdrop" />
				</div>
			)}

		</React.Fragment>
	);
}