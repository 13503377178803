import { postBontu, getBontu } from "./requests";

const userPath = "/user-client";
const clientDocument = "/flujo-piloto/cliente/documentos"
export function registerClient(body) {
	return postBontu(userPath, { 'usertype': 'amazon' }, body);
}

export async function getClientDocument(id) {
	return getBontu(clientDocument, null, { id: id });
}