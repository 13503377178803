const environment = {
	production: false,
	client_id: 'amzn1.application-oa2-client.9801cbca613c4c449c2bfa14d939c158',
	public_key: 'JEZ9yY9Jkd6n2FBEquTdC536sXthBpxv39tyJJB4',
	devURL: 'https://dev.bontu-dev.io/',
	chat: {
		host: 'https://wchat.freshchat.com',
		token: '83ff6496-0c31-41db-9072-3f539aa3cca0'
	},
	cognitoConfig: {
	  Auth: {
		identityPoolId: 'us-east-1:2c9c3b15-238f-4c7c-a902-1288bda038a6',
		region: 'us-east-1',
		identityPoolRegion: 'us-east-1',
		userPoolId: 'us-east-1_tr9hPc16p',
		userPoolWebClientId: '188s9erfirp6p54on00q963us9',
		oauth: {
		  domain: 'dev-userappauth.auth.us-east-1.amazoncognito.com',
		  redirectSignIn: 'https://www.dev-userapp-r.bontu-dev.io/callback',
		  redirectSignOut: 'https://www.dev-userapp-r.bontu-dev.io/login',
		  responseType: 'token'
		}
	  },
	  API: {
		endpoints: [
		  {
			name: 'ApiBontu',
			endpoint: 'https://api.bontu-dev.io/dev'
		  }
		]
	  }
	},
};

export default environment;